.Banners {
  border-radius: 5px;
  position: relative;
  width: 100%;
  height: 270px;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .Banners {
    height: 255px;
    border-radius: 0;
  }
}
@media only screen and (max-width: 400px) {
  .Banners {
    height: 220px;
  }
}
.Banners__img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.Banners__img_active {
  opacity: 1;
}
