.About {
  display: flex;
}
.About__text-wrapper {
  flex: 1;
  margin-right: 1rem;
}
@media only screen and (max-width: 768px) {
  .About__text-wrapper {
    margin-right: 0;
  }
}
.About__text {
  color: #636363;
  line-height: 1.4;
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
}
.About__img-wrapper {
  flex: 1;
  width: 100%;
  height: 250px;
  background-color: #eae9e9;
  background: linear-gradient(-45deg, #d2d2d2, #eae9e9, #adadad);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}
@media only screen and (max-width: 768px) {
  .About__img-wrapper {
    display: none;
  }
}
.About__img {
  width: 100%;
  height: 250px;
  opacity: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.About__img_load {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}
.About__fallback {
  width: 100%;
  height: 250px;
}
