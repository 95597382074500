.BannerControls {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .BannerControls {
    display: none;
  }
}
.BannerControls__progress {
  width: 60px;
  height: 2px;
  background-color: rgba(255,255,255,0.5);
  background-image: linear-gradient(90deg, #ef7f1a, #fe7200);
  background-repeat: no-repeat;
  box-shadow: 0 1px 1px rgba(0,0,0,0.3);
  border-radius: 10px;
  transition: background-size 0.5s ease-in-out;
}
.BannerControls__count {
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-bottom: 4px;
}
.BannerControls__btn {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 0.6;
  transition: opacity 250ms ease-in-out;
}
.BannerControls__btn:hover {
  opacity: 1;
}
.BannerControls__btn_left {
  margin-right: 10px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCiAgICAgeT0iMHB4IgogICAgIHZpZXdCb3g9IjAgMCA0OTIuMDA0IDQ5Mi4wMDQiIGZpbGw9IndoaXRlIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OTIuMDA0IDQ5Mi4wMDQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNMzgyLjY3OCwyMjYuODA0TDE2My43Myw3Ljg2QzE1OC42NjYsMi43OTIsMTUxLjkwNiwwLDE0NC42OTgsMHMtMTMuOTY4LDIuNzkyLTE5LjAzMiw3Ljg2bC0xNi4xMjQsMTYuMTIKCQkJYy0xMC40OTIsMTAuNTA0LTEwLjQ5MiwyNy41NzYsMCwzOC4wNjRMMjkzLjM5OCwyNDUuOWwtMTg0LjA2LDE4NC4wNmMtNS4wNjQsNS4wNjgtNy44NiwxMS44MjQtNy44NiwxOS4wMjgKCQkJYzAsNy4yMTIsMi43OTYsMTMuOTY4LDcuODYsMTkuMDRsMTYuMTI0LDE2LjExNmM1LjA2OCw1LjA2OCwxMS44MjQsNy44NiwxOS4wMzIsNy44NnMxMy45NjgtMi43OTIsMTkuMDMyLTcuODZMMzgyLjY3OCwyNjUKCQkJYzUuMDc2LTUuMDg0LDcuODY0LTExLjg3Miw3Ljg0OC0xOS4wODhDMzkwLjU0MiwyMzguNjY4LDM4Ny43NTQsMjMxLjg4NCwzODIuNjc4LDIyNi44MDR6Ii8+Cgk8L2c+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KPC9zdmc+Cg==);
  transform: rotate(180deg);
}
.BannerControls__btn_right {
  margin-left: 10px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCiAgICAgeT0iMHB4IgogICAgIHZpZXdCb3g9IjAgMCA0OTIuMDA0IDQ5Mi4wMDQiIGZpbGw9IndoaXRlIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OTIuMDA0IDQ5Mi4wMDQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNMzgyLjY3OCwyMjYuODA0TDE2My43Myw3Ljg2QzE1OC42NjYsMi43OTIsMTUxLjkwNiwwLDE0NC42OTgsMHMtMTMuOTY4LDIuNzkyLTE5LjAzMiw3Ljg2bC0xNi4xMjQsMTYuMTIKCQkJYy0xMC40OTIsMTAuNTA0LTEwLjQ5MiwyNy41NzYsMCwzOC4wNjRMMjkzLjM5OCwyNDUuOWwtMTg0LjA2LDE4NC4wNmMtNS4wNjQsNS4wNjgtNy44NiwxMS44MjQtNy44NiwxOS4wMjgKCQkJYzAsNy4yMTIsMi43OTYsMTMuOTY4LDcuODYsMTkuMDRsMTYuMTI0LDE2LjExNmM1LjA2OCw1LjA2OCwxMS44MjQsNy44NiwxOS4wMzIsNy44NnMxMy45NjgtMi43OTIsMTkuMDMyLTcuODZMMzgyLjY3OCwyNjUKCQkJYzUuMDc2LTUuMDg0LDcuODY0LTExLjg3Miw3Ljg0OC0xOS4wODhDMzkwLjU0MiwyMzguNjY4LDM4Ny43NTQsMjMxLjg4NCwzODIuNjc4LDIyNi44MDR6Ii8+Cgk8L2c+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KPC9zdmc+Cg==);
}
