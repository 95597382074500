.ShareButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ShareButtons__text {
  margin-right: 0.5rem;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .ShareButtons__text {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .ShareButtons__group {
    width: 100%;
    text-align: center;
  }
}
.ShareButtons__button {
  opacity: 0.6;
  margin-right: 5px;
  transition: 150ms opacity ease-in;
}
.ShareButtons__button:hover {
  opacity: 1;
}
.ShareButtons__button:last-child {
  margin-right: 0;
}
