.Filters__item {
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .Filters__item {
    margin-right: 0.7rem;
  }
}
.Filters__item:last-child {
  margin-bottom: 0;
}
.Filters__item_inline {
  display: inline-block;
  margin-right: 0.7rem;
}
