.Feedback {
  display: flex;
}
.Feedback__left {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .Feedback__left {
    display: none;
  }
}
.Feedback__right {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .Feedback__right {
    width: 100%;
  }
}
.Feedback__left-title {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1rem;
  margin-left: 40%;
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .Feedback__left-title {
    margin-left: 0;
  }
}
.Feedback__left-description {
  color: #fff;
  margin-left: 40%;
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .Feedback__left-description {
    margin-left: 0;
  }
}
.Feedback__right-title {
  font-size: 19px;
  margin-right: 40%;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .Feedback__right-title {
    margin-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .Feedback__right-title {
    margin-right: 0;
    font-size: 15px;
  }
}
.Feedback__right-form {
  margin-right: 40%;
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .Feedback__right-form {
    margin-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .Feedback__right-form {
    margin-right: 0;
  }
}
