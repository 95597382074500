.CatalogCard {
  width: 100%;
  max-height: 270px;
  height: 100vw;
  color: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: default;
  transition: color 250ms;
}
.CatalogCard:hover .CatalogCard__link {
  color: #ef7f1a;
}
.CatalogCard_non-interaction {
  pointer-events: none;
}
.CatalogCard__link {
  transition: color 250ms;
  color: #3e3a39;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.CatalogCard__image-wrapper {
  width: 100%;
  overflow: hidden;
  flex: 1;
  border-radius: 5px;
  cursor: pointer;
  background-color: #eae9e9;
  background: linear-gradient(-45deg, #d2d2d2, #eae9e9, #adadad);
  background-size: 400% 400%;
}
.CatalogCard__image-link {
  display: block;
  height: 100%;
}
.CatalogCard__image {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 0;
}
@media only screen and (min-width: 768px) {
  .CatalogCard__image {
    transform: scale(1.01);
    transition: all 2s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .CatalogCard__image:hover {
    transform: scale(1.2);
    transition: transform 20s ease-out;
  }
}
.CatalogCard__image_load {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}
.CatalogCard__footer {
  padding: 0.5rem 0;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.CatalogCard__title {
  cursor: pointer;
  color: #000 !important;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
  font-size: 14px;
}
@media only screen and (min-width: 768px) {
  .CatalogCard__title {
    font-size: 16px;
  }
}
.CatalogCard__price-wrapper {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CatalogCard__price {
  font-size: 16px;
  font-weight: bold;
  color: #ef7f1a;
  white-space: nowrap;
}
