.Pagination__item_disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.6;
}
.Pagination__item_disabled:hover {
  background-color: transparent !important;
}
.Pagination__item_active {
  border: 1px solid #adadad;
}
.Pagination__item:hover {
  background-color: #eae9e9;
}
.Pagination__item_prev {
  transform: rotate(180deg);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCiAgICAgeT0iMHB4IgogICAgIHZpZXdCb3g9IjAgMCA0OTIuMDA0IDQ5Mi4wMDQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ5Mi4wMDQgNDkyLjAwNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0zODIuNjc4LDIyNi44MDRMMTYzLjczLDcuODZDMTU4LjY2NiwyLjc5MiwxNTEuOTA2LDAsMTQ0LjY5OCwwcy0xMy45NjgsMi43OTItMTkuMDMyLDcuODZsLTE2LjEyNCwxNi4xMgoJCQljLTEwLjQ5MiwxMC41MDQtMTAuNDkyLDI3LjU3NiwwLDM4LjA2NEwyOTMuMzk4LDI0NS45bC0xODQuMDYsMTg0LjA2Yy01LjA2NCw1LjA2OC03Ljg2LDExLjgyNC03Ljg2LDE5LjAyOAoJCQljMCw3LjIxMiwyLjc5NiwxMy45NjgsNy44NiwxOS4wNGwxNi4xMjQsMTYuMTE2YzUuMDY4LDUuMDY4LDExLjgyNCw3Ljg2LDE5LjAzMiw3Ljg2czEzLjk2OC0yLjc5MiwxOS4wMzItNy44NkwzODIuNjc4LDI2NQoJCQljNS4wNzYtNS4wODQsNy44NjQtMTEuODcyLDcuODQ4LTE5LjA4OEMzOTAuNTQyLDIzOC42NjgsMzg3Ljc1NCwyMzEuODg0LDM4Mi42NzgsMjI2LjgwNHoiLz4KCTwvZz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgo8L3N2Zz4K);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 55% center;
}
.Pagination__item_next {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCiAgICAgeT0iMHB4IgogICAgIHZpZXdCb3g9IjAgMCA0OTIuMDA0IDQ5Mi4wMDQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ5Mi4wMDQgNDkyLjAwNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0zODIuNjc4LDIyNi44MDRMMTYzLjczLDcuODZDMTU4LjY2NiwyLjc5MiwxNTEuOTA2LDAsMTQ0LjY5OCwwcy0xMy45NjgsMi43OTItMTkuMDMyLDcuODZsLTE2LjEyNCwxNi4xMgoJCQljLTEwLjQ5MiwxMC41MDQtMTAuNDkyLDI3LjU3NiwwLDM4LjA2NEwyOTMuMzk4LDI0NS45bC0xODQuMDYsMTg0LjA2Yy01LjA2NCw1LjA2OC03Ljg2LDExLjgyNC03Ljg2LDE5LjAyOAoJCQljMCw3LjIxMiwyLjc5NiwxMy45NjgsNy44NiwxOS4wNGwxNi4xMjQsMTYuMTE2YzUuMDY4LDUuMDY4LDExLjgyNCw3Ljg2LDE5LjAzMiw3Ljg2czEzLjk2OC0yLjc5MiwxOS4wMzItNy44NkwzODIuNjc4LDI2NQoJCQljNS4wNzYtNS4wODQsNy44NjQtMTEuODcyLDcuODQ4LTE5LjA4OEMzOTAuNTQyLDIzOC42NjgsMzg3Ljc1NCwyMzEuODg0LDM4Mi42NzgsMjI2LjgwNHoiLz4KCTwvZz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgo8L3N2Zz4K);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 55% center;
}
.Pagination__show-more {
  margin-bottom: 20px;
}
.Pagination__items {
  display: flex;
  justify-content: center;
}
