.ButtonsTabBar {
  display: flex;
  margin-bottom: 1rem;
}
.ButtonsTabBar__heading {
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: 1px solid #eae9e9;
  cursor: pointer;
}
.ButtonsTabBar__heading:first-child {
  border-radius: 5px 0 0 5px;
}
.ButtonsTabBar__heading:last-child {
  border-radius: 0 5px 5px 0;
}
.ButtonsTabBar__heading_active {
  background-color: #d4b390;
  border-color: transparent;
  color: #fff;
  cursor: default;
}
