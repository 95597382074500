.ColumnsItem {
  min-width: 1px;
}
.ColumnsItem_is-first {
  margin-left: 0 !important;
}
.ColumnsItem_offset1 {
  margin-left: 0.5rem;
}
.ColumnsItem_offset2 {
  margin-left: 1rem;
}
@media only screen and (max-width: 768px) {
  .ColumnsItem_is-mob-full {
    flex: auto !important;
  }
}
@media only screen and (max-width: 768px) {
  .ColumnsItem_is-mob-hidden {
    display: none;
  }
}
