.Slide {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media only screen and (max-width: 768px) {
  .Slide {
    border-radius: 0;
  }
}
.Slide__link {
  display: block;
  height: 100%;
}
.Slide__image {
  height: 100%;
}
@media only screen and (max-width: 400px) {
  .Slide__image {
    width: 100%;
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
}
.Slide_pointer {
  cursor: pointer;
}
.Slide__description {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
  background: #fff;
  padding: 1rem;
  box-sizing: border-box;
}
