.TopContacts {
  padding: 1rem;
}
@media only screen and (max-width: 768px) {
  .TopContacts {
    padding: 0.7rem 1rem;
  }
}
.TopContacts__mobile {
  display: none;
}
@media only screen and (max-width: 768px) {
  .TopContacts__mobile {
    display: flex;
  }
}
.TopContacts__desktop {
  display: flex;
  vertical-align: center;
}
@media only screen and (max-width: 768px) {
  .TopContacts__desktop {
    display: none;
  }
}
.TopContacts__item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}
.TopContacts__item_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  width: 100%;
  text-align: center;
}
.TopContacts__item_callme {
  margin-left: auto;
  margin-right: 0;
}
.TopContacts__item_last {
  margin-right: 0;
}
.TopContacts__item_mobile-first,
.TopContacts__item_mobile-last {
  flex: none;
}
.TopContacts__item_mobile-last {
  margin-right: 0;
  margin-left: 1rem;
}
.TopContacts__item_address {
  flex: 1;
}
