.Filter {
  margin-left: 0;
  color: #3e3a39;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 5px;
  text-decoration: none;
}
.Filter:hover {
  font-weight: bold;
}
.Filter_active {
  font-weight: bold;
}
.Filter_active:after {
  content: '\D7';
  display: inline-block;
  margin-left: 3px;
}
