.FadeAndScale {
  opacity: 0;
  transition: all 250ms !important;
  z-index: 999;
  position: relative;
}
.FadeAndScale_exit {
  opacity: 0;
}
.FadeAndScale_enter {
  opacity: 0;
}
.FadeAndScale_enter-done {
  opacity: 1;
}
