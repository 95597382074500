@media only screen and (max-width: 400px) {
  ::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .Grid_is-scroll {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    overflow-scrolling: touch;
    scrollbar-width: none;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
@media only screen and (max-width: 768px) {
  .Grid_is-scroll .Grid__list {
    padding: 0 1rem;
    box-sizing: border-box;
    display: inline-block;
    margin: 8px 0;
  }
}
@media only screen and (max-width: 768px) {
  .Grid_is-scroll .Grid__list-item {
    display: inline-block;
    vertical-align: middle;
    width: 155px !important;
    margin: 0 0.5rem 0 0;
  }
  .Grid_is-scroll .Grid__list-item:first-child {
    margin-left: 0;
  }
  .Grid_is-scroll .Grid__list-item:last-child {
    margin-right: 0;
  }
}
.Grid_is-scroll .Grid__list-last-item {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .Grid_is-scroll .Grid__list-last-item {
    display: inline-block;
    padding-right: 1rem;
    box-sizing: border-box;
    margin-top: 0;
  }
}
.Grid_items-in-row-4 .Grid__list-item {
  width: calc(25% - 1rem);
}
@media only screen and (max-width: 1000px) {
  .Grid_items-in-row-4 .Grid__list-item {
    width: calc(33.333% - 1rem);
  }
}
@media only screen and (max-width: 768px) {
  .Grid_items-in-row-4 .Grid__list-item {
    width: calc(50% - 1rem);
  }
}
.Grid_items-in-row-3 .Grid__list-item {
  width: calc(33.33% - 1rem);
}
@media only screen and (max-width: 950px) {
  .Grid_items-in-row-3 .Grid__list-item {
    width: calc(50% - 1rem);
  }
}
.Grid__list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;
}
@media only screen and (max-width: 768px) {
  .Grid__list {
    margin: -8px;
  }
}
.Grid__list-item {
  margin: 0.5rem;
}
.Grid__list-last-item {
  text-align: center;
  margin-top: 1rem;
}
