.Backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  max-width: 300px;
  height: 100%;
  margin-right: 50px;
  transform: translateX(-100%);
  transition: all 250ms cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #fff;
  visibility: hidden;
}
.Backdrop_open {
  visibility: visible !important;
  transform: translateX(0);
}
