.MotivationSection__title {
  font-weight: bold;
  color: #ae937c;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0.7rem;
}
.MotivationSection__description {
  line-height: 1.5;
}
