.Footer {
  padding: 1rem 1rem;
}
.Footer__sections {
  display: flex;
  justify-content: space-between;
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .Footer__sections {
    flex-direction: column;
  }
}
.Footer__copyright {
  color: #fff;
  font-size: 11px;
  text-align: center;
  margin: 0;
  margin-top: 1rem;
}
