.Text {
  margin: 0;
  color: #544f4e;
  line-height: 1.5;
}
.Text_align-left {
  text-align: left;
}
.Text_align-right {
  text-align: right;
}
.Text_block {
  display: block;
}
.Text_bold {
  font-weight: bold;
}
.Text_level-1 {
  font-size: 30px;
}
.Text_level-2 {
  font-size: 25px;
}
.Text_level-3 {
  font-size: 20px;
}
.Text_level-4 {
  font-size: 18px;
}
.Text_level-5 {
  font-size: 16px;
}
.Text_level-6 {
  font-size: 14px;
}
.Text_color-white {
  color: #fff;
}
.Text_color-gray {
  color: #adadad;
}
.Text_color-danger {
  color: #ca2222;
}
