.ContactsInfoItem {
  margin-bottom: 1rem;
}
.ContactsInfoItem__name {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0.3rem;
}
.ContactsInfoItem__content {
  line-height: 1.5;
}
.ContactsInfoItem__content-clipboard {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDQ1IDQ1Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Ik0yOC41LDBINC41QTQuNTEsNC41MSwwLDAsMCwwLDQuNXYyNEE0LjUxLDQuNTEsMCwwLDAsNC41LDMzaDI0QTQuNTEsNC41MSwwLDAsMCwzMywyOC41VjQuNUE0LjUxLDQuNTEsMCwwLDAsMjguNSwwWm0tMywyNkg3LjVhMS41LDEuNSwwLDAsMSwwLTNoMThhMS41LDEuNSwwLDAsMSwwLDNabTAtOEg3LjVhMS41LDEuNSwwLDAsMSwwLTNoMThhMS41LDEuNSwwLDAsMSwwLDNabTAtOEg3LjVhMS41LDEuNSwwLDAsMSwwLTNoMThhMS41LDEuNSwwLDAsMSwwLDNaIiBmaWxsPSIjZWY3ZjFhIi8+CiAgICA8cGF0aCBkPSJNNDAuNSwxMmgtNHYzaDRBMS41LDEuNSwwLDAsMSw0MiwxNi41djI0QTEuNSwxLjUsMCwwLDEsNDAuNSw0MmgtMjRBMS41LDEuNSwwLDAsMSwxNSw0MC41di00SDEydjRBNC41MSw0LjUxLDAsMCwwLDE2LjUsNDVoMjRBNC41MSw0LjUxLDAsMCwwLDQ1LDQwLjV2LTI0QTQuNTEsNC41MSwwLDAsMCw0MC41LDEyWiIgZmlsbD0iI2VmN2YxYSIvPgogIDwvZz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 5px;
  opacity: 0.7;
  transition: 250ms opacity;
  cursor: pointer;
}
.ContactsInfoItem__content-clipboard:hover {
  opacity: 1;
}
