.Motivation {
  display: flex;
}
.Motivation > div {
  flex: 1;
  margin-right: 1rem;
}
@media only screen and (max-width: 400px) {
  .Motivation > div {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.Motivation > div:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 400px) {
  .Motivation {
    display: block;
  }
}
