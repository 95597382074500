.Price {
  font-size: 18px;
  line-height: 1;
}
@media only screen and (max-width: 400px) {
  .Price {
    font-size: 16px;
  }
}
.Price__price-wrapper {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Price__price {
  font-size: inherit;
  font-weight: bold;
  color: #ef7f1a;
  white-space: nowrap;
}
