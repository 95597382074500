.TopNavSubItems {
  position: relative;
}
.TopNavSubItems__hover-item {
  position: relative;
}
.TopNavSubItems__hover-item:after {
  content: '\203A';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  transform: translateY(-50%) rotate(90deg);
}
.TopNavSubItems__hover-item_is-open {
  color: #fff;
}
.TopNavSubItems__hover-item_is-open:after {
  content: '\203A';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  transform: translateY(-50%) translateX(2px) rotate(-90deg);
}
.TopNavSubItems__menu-item {
  padding: 0.7rem 1rem;
  display: block !important;
  color: #fff;
}
.TopNavSubItems__menu-item:hover {
  color: #fff;
  background-color: #d8bb9b;
}
.TopNavSubItems__submenu {
  position: absolute;
  border-radius: 3px;
  top: 100%;
  right: 0;
  display: none;
  box-sizing: border-box;
  min-width: 250px;
  max-height: 300px;
  overflow-y: auto;
  background-color: #d4b390;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.TopNavSubItems__submenu_enter,
.TopNavSubItems__submenu_enter-done,
.TopNavSubItems__submenu_exit {
  display: block;
  transition: all 200ms ease;
}
.TopNavSubItems__submenu_enter {
  transform: translateY(10px);
  opacity: 0;
}
.TopNavSubItems__submenu_enter-done {
  transform: translateY(0);
  opacity: 1;
  z-index: 100;
}
.TopNavSubItems__submenu_exit {
  transform: translateY(10px);
  opacity: 0;
}
