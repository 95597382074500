.AsideNav {
  width: 100%;
  height: 100%;
  background-color: #3e3a39;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 13px;
}
.AsideNav__links {
  flex-grow: 1;
}
.AsideNav__logo {
  padding: 0.7rem;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid #adadad;
}
.AsideNav__links {
  padding: 0.7rem 0;
  box-sizing: border-box;
  overflow-y: auto;
}
.AsideNav__footer {
  padding: 0.7rem;
  box-sizing: border-box;
}
