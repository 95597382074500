.Select {
  outline: none;
}
.Select .Select__control {
  min-height: auto;
  border: 1px solid #d2d2d2;
  transition: all 150ms ease-in;
}
.Select .Select__control:hover {
  border-color: #ef7f1a;
}
.Select .Select__control--is-focused {
  border: 1px solid #ef7f1a !important;
  box-shadow: none;
}
.Select .Select__indicator {
  padding: 0 0.3rem;
  box-sizing: border-box;
}
.Select .Select__value-container {
  padding: 0.5rem;
  box-sizing: border-box;
}
.Select .Select__value-container > .css-b8ldur-Input {
  margin: 0;
  padding: 0;
}
.Select > .css-1g6gooi {
  padding: 0 !important;
  margin: 0 !important;
}
.Select__value-container--has-value.Select__value-container--is-multi {
  padding: 0.29rem !important;
}
