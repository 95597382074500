.GalleryCard {
  width: 100%;
  max-height: 270px;
  height: 100vw;
  display: block;
  position: relative;
  border-radius: 5px;
  text-decoration: none;
  color: #3e3a39;
  overflow: hidden;
}
.GalleryCard:hover .GalleryCard__link {
  color: #ef7f1a;
}
.GalleryCard_non-interaction {
  pointer-events: none;
}
.GalleryCard__link {
  transition: color 250ms;
  color: #3e3a39;
}
.GalleryCard:visited {
  color: inherit;
}
.GalleryCard__image-wrap {
  height: 80%;
  border-radius: 5px;
  overflow: hidden;
  background-color: #eae9e9;
  background: linear-gradient(-45deg, #d2d2d2, #eae9e9, #adadad);
  background-size: 400% 400%;
}
.GalleryCard__image-link {
  height: 100%;
  display: block;
}
.GalleryCard__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
}
@media only screen and (min-width: 768px) {
  .GalleryCard__image {
    transform: scale(1.01);
    transition: all 2s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .GalleryCard__image:hover {
    transform: scale(1.2);
    transition: transform 20s ease-out;
  }
}
.GalleryCard__image_load {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}
.GalleryCard__title {
  height: 20%;
  font-size: 14px;
  margin-top: 0.5rem;
  white-space: break-spaces;
  text-align: center;
}
