.CalculationProductCard {
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  margin-bottom: 0.5rem;
  display: flex;
  transition: all 150ms ease-in;
  outline: none;
  cursor: pointer;
}
.CalculationProductCard:focus {
  border-color: #ef7f1a;
}
.CalculationProductCard:hover {
  border-color: #ef7f1a;
}
.CalculationProductCard:hover .CalculationProductCard__buttons {
  opacity: 1;
}
.CalculationProductCard__content {
  flex: 1;
}
.CalculationProductCard__buttons {
  opacity: 1;
}
.CalculationProductCard__remove-button {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}
.CalculationProductCard__remove-button {
  font-size: 23px;
  color: #adadad;
}
.CalculationProductCard__remove-button:hover {
  color: #3e3a39;
}
.CalculationProductCard__title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.3rem;
}
.CalculationProductCard__size-numbers {
  color: #ef7f1a;
}
.CalculationProductCard__material {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}
.CalculationProductCard__material-item {
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 3px 5px;
  background-color: #eae9e9;
  box-sizing: border-box;
}
