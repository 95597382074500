[class*="balloon_layout_normal"] {
  box-shadow: 0 10px 20px -5px rgba(0,0,0,0.4) !important;
  border-radius: 15px;
}
.MapOfficePlacemark {
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
}
.MapOfficePlacemark__icon {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNiIgaGVpZ2h0PSI0NSIgdmlld0JveD0iMCAwIDM2IDQ1Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Ik0xOCwwQTE4LDE4LDAsMCwwLDAsMThjMCw5Ljk0LDE4LDI3LDE4LDI3UzM2LDI3Ljk0LDM2LDE4QTE4LDE4LDAsMCwwLDE4LDBabTAsMzFBMTQsMTQsMCwxLDEsMzIsMTcsMTQsMTQsMCwwLDEsMTgsMzFaIiBmaWxsPSIjZWY3ZjFhIi8+CiAgICA8cGF0aCBkPSJNMjcuNSwxNSwxOC40MSw4LjNhLjY5LjY5LDAsMCwwLS44MiwwTDguNSwxNWgyLjMxYS42OS42OSwwLDAsMSwuNjkuNjl2Ny42MmEuNjkuNjksMCwwLDAsLjY5LjY5aDIuNjJhLjY5LjY5LDAsMCwwLC42OS0uNjlWMTguNjlhLjY5LjY5LDAsMCwxLC42OS0uNjloMy42MmEuNjkuNjksMCwwLDEsLjY5LjY5djQuNjJhLjY5LjY5LDAsMCwwLC42OS42OWgyLjYyYS42OS42OSwwLDAsMCwuNjktLjY5VjE1LjY5YS42OS42OSwwLDAsMSwuNjktLjY5WiIgZmlsbD0iI2VmN2YxYSIvPgogIDwvZz4KPC9zdmc+Cg==);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.MapOfficePlacemark__balloon-name {
  font-size: 20px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
