.PageSection {
  background-color: transparent;
}
.PageSection_clear-top {
  padding-top: 0 !important;
}
.PageSection_outer-offset {
  margin-bottom: 1rem;
}
.PageSection_inner-offset {
  padding: 1rem;
  box-sizing: border-box;
}
.PageSection_background-gray {
  background-color: #eae9e9;
}
.PageSection_background-primary {
  background-color: #d4b390;
}
.PageSection__title {
  padding-top: 1rem;
  padding-bottom: 2rem;
}
@media only screen and (max-width: 768px) {
  .PageSection__title {
    padding-top: 0;
    padding-bottom: 1rem;
    text-align: center !important;
  }
}
.PageSection__title_center {
  text-align: center;
}
.PageSection__title_left {
  text-align: left;
}
.PageSection__title_right {
  text-align: right;
}
.PageSection__heading {
  border-bottom: 2px solid transparent;
}
@media only screen and (max-width: 768px) {
  .PageSection__content_is-mob-full-width {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
