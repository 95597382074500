.News {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .News {
    display: block;
  }
}
.News__month {
  width: 15%;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .News__month {
    width: 100%;
  }
}
.News__sticky {
  position: sticky;
  top: 100px;
}
@media only screen and (max-width: 768px) {
  .News__sticky {
    position: static;
    display: flex;
    align-items: center;
  }
}
.News__year {
  margin-top: 5px;
  color: #d2d2d2;
}
@media only screen and (max-width: 768px) {
  .News__year {
    margin-top: 0;
    margin-left: 5px;
  }
}
.News__posts {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .News__posts {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .News__posts {
    display: block;
  }
}
.News__posts > section {
  flex: 1;
  margin: 1rem;
  min-width: calc(33.333% - 2rem);
}
@media only screen and (min-width: 400px) and (max-width: 1050px) {
  .News__posts > section {
    min-width: calc(50% - 2rem);
  }
}
@media only screen and (max-width: 400px) {
  .News__posts > section {
    margin-left: 0;
    margin-right: 0;
  }
}
