.DefaultTabBar {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eae9e9;
}
@media only screen and (max-width: 768px) {
  .DefaultTabBar {
    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    scroll-snap-type: mandatory;
  }
}
.DefaultTabBar:before {
  content: '';
  display: block;
  width: 1px;
  padding-right: 1rem;
}
.DefaultTabBar:after {
  content: '';
  display: block;
  width: 1px;
  padding-left: 1rem;
}
.DefaultTabBar__heading {
  cursor: pointer;
  padding: 0.5rem 0.2rem;
  margin-right: 1rem;
  box-sizing: border-box;
  transition: all 250ms;
  border-bottom: 2px solid transparent;
}
@media only screen and (max-width: 768px) {
  .DefaultTabBar__heading {
    white-space: nowrap;
  }
}
.DefaultTabBar__heading:hover {
  font-weight: bold;
}
.DefaultTabBar__heading:last-child {
  margin-right: 0;
}
.DefaultTabBar__heading_active {
  font-weight: bold;
  cursor: auto;
  border-color: #d4b390;
}
