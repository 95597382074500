.SnackbarProvider {
  position: fixed;
  bottom: 0;
  right: 0;
  min-width: 300px;
  max-width: 400px;
  z-index: 999;
  box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
  .SnackbarProvider {
    width: 100%;
  }
}
