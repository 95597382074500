.Modal {
  position: fixed;
  background: #fff;
  z-index: 9999;
  transition: height 250ms;
  display: flex;
  flex-direction: column;
  font-family: 'Exo 2', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: 0;
  text-transform: none;
  font-size: 13px;
}
.Modal__modal,
.Modal__popup,
.Modal__alert {
  box-shadow: 0 2px 8px 4px rgba(0,0,0,0.3);
  min-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
}
@media only screen and (max-width: 700px) {
  .Modal__modal,
  .Modal__popup,
  .Modal__alert {
    width: 100% !important;
    min-width: 100% !important;
  }
}
@media only screen and (max-width: 400px) {
  .Modal__modal,
  .Modal__popup,
  .Modal__alert {
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    top: 0;
    left: 0;
    transform: none;
  }
}
@media only screen and (max-width: 400px) {
  .Modal__alert {
    width: 100% !important;
    min-width: 100% !important;
    min-height: auto !important;
    height: auto !important;
    top: 0;
    left: 0;
    transform: translateY(100%);
  }
}
.Modal__header {
  padding: 1rem;
  box-sizing: border-box;
  border-bottom: 1px solid #eae9e9;
  display: flex;
  align-items: center;
}
.Modal__title {
  margin-right: 15px;
}
.Modal__content {
  flex: 1;
  overflow-x: auto;
}
.Modal__close-button {
  border: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0Ny45NzEgNDcuOTcxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0Ny45NzEgNDcuOTcxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4LjIyOCwyMy45ODZMNDcuMDkyLDUuMTIyYzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMEwyMy45ODYsMTkuNzQ0TDUuMTIxLDAuODgKCQljLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMGMtMS4xNzIsMS4xNzEtMS4xNzIsMy4wNzEsMCw0LjI0MmwxOC44NjUsMTguODY0TDAuODc5LDQyLjg1Yy0xLjE3MiwxLjE3MS0xLjE3MiwzLjA3MSwwLDQuMjQyCgkJQzEuNDY1LDQ3LjY3NywyLjIzMyw0Ny45NywzLDQ3Ljk3czEuNTM1LTAuMjkzLDIuMTIxLTAuODc5bDE4Ljg2NS0xOC44NjRMNDIuODUsNDcuMDkxYzAuNTg2LDAuNTg2LDEuMzU0LDAuODc5LDIuMTIxLDAuODc5CgkJczEuNTM1LTAuMjkzLDIuMTIxLTAuODc5YzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJMMjguMjI4LDIzLjk4NnoiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  width: 17px;
  height: 17px;
  right: 4px;
  position: relative;
  margin-left: auto;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 250ms;
}
.Modal__close-button:hover {
  opacity: 1;
}
