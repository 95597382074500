.AsideNavLink {
  display: block;
  padding: 0.7rem 1.5rem;
  color: #fff;
  text-decoration: none;
  transition: color 200ms ease;
}
.AsideNavLink:hover {
  color: #d2d2d2;
}
