.TopNav {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.TopNav__logo {
  margin-right: auto;
}
.TopNav__links {
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  box-sizing: border-box;
}
