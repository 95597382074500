.NewsBannerItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.NewsBannerItem_isBlack .NewsBannerItem__title,
.NewsBannerItem_isBlack .NewsBannerItem__description {
  color: #3e3a39 !important;
}
.NewsBannerItem_isWhite .NewsBannerItem__title,
.NewsBannerItem_isWhite .NewsBannerItem__description {
  color: #fff !important;
}
.NewsBannerItem__link {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
}
.NewsBannerItem__link:hover .NewsBannerItem__background-mask {
  opacity: 1;
}
.NewsBannerItem__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.NewsBannerItem__background {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.NewsBannerItem__background-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 250ms ease-out;
}
.NewsBannerItem__content {
  padding: 0 1.5rem;
  box-sizing: border-box;
}
.NewsBannerItem__title {
  max-height: 100px;
  overflow: hidden;
}
.NewsBannerItem__description {
  margin-top: 1rem !important;
  max-height: 60px;
  overflow: hidden;
}
.NewsBannerItem__types {
  width: 100%;
  padding: 0 1.5rem 1rem 1.5rem;
  box-sizing: border-box;
  z-index: 1;
}
.NewsBannerItem__type {
  display: inline-block;
  background: #fff;
  border-radius: 5px;
  padding: 3px 5px;
  margin-right: 7px;
}
