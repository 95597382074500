.Input {
  width: 100%;
  outline: none;
  background: #fff !important;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 150ms ease-in;
  color: #3e3a39;
}
.Input:focus {
  border-color: #ef7f1a;
}
.Input:hover {
  border-color: #ef7f1a;
}
.Input_theme-type1 {
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: transparent;
}
.Input_theme-type1::-moz-placeholder {
  color: #4e4a49;
}
.Input_theme-type1::placeholder {
  color: #4e4a49;
}
.Input_theme-type2 {
  padding: 0.5rem;
  box-sizing: border-box;
  border-color: #d2d2d2;
  background-color: transparent;
}
.Input_error {
  border-color: #ca2222;
}
