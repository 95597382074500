.Hamburger {
  cursor: pointer;
  width: 28px;
  height: 25px;
  box-sizing: border-box;
  outline: none;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.Hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #eae9e9;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.Hamburger span:nth-child(1) {
  top: 0;
}
.Hamburger span:nth-child(2),
.Hamburger span:nth-child(3) {
  top: 10px;
}
.Hamburger span:nth-child(4) {
  top: 20px;
}
.Hamburger_is-open span:nth-child(1) {
  top: 10px;
  width: 0;
  left: 50%;
}
.Hamburger_is-open span:nth-child(2) {
  transform: rotate(45deg);
}
.Hamburger_is-open span:nth-child(3) {
  transform: rotate(-45deg);
}
.Hamburger_is-open span:nth-child(4) {
  top: 10px;
  width: 0;
  left: 50%;
}
