html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
details,
section,
summary,
aside,
main,
menu,
nav,
figcaption,
figure,
footer,
header,
hgroup {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
b,
strong {
  font-weight: bold;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
.fixed {
  overflow: hidden;
}
.Layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Exo 2', sans-serif;
  font-size: 13px;
  color: #3e3a39;
  background-size: 105%;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0;
  text-transform: none;
  position: relative;
}
.Layout__progressbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.Layout__container {
  box-sizing: border-box;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .Layout__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.Layout__contacts {
  background-color: #3e3a39;
  border-bottom: 1px solid rgba(76,76,76,0.81);
}
@media only screen and (max-width: 768px) {
  .Layout__contacts {
    position: sticky;
    top: 0;
    z-index: 9;
    box-shadow: 0 2px 2px rgba(0,0,0,0.12);
  }
}
.Layout__navbar {
  background-color: #3e3a39;
  position: sticky;
  top: 0;
  z-index: 9;
  box-shadow: 0 2px 2px rgba(0,0,0,0.12);
}
@media only screen and (max-width: 768px) {
  .Layout__navbar {
    display: none;
  }
}
.Layout__main {
  flex-grow: 1;
}
.Layout__footer {
  background-color: #3e3a39;
}
@media only screen and (max-width: 768px) {
  .Layout__footer_with-footer-nav {
    margin-bottom: 50px;
  }
}
.Layout__footer-nav {
  display: none;
}
@media only screen and (max-width: 768px) {
  .Layout__footer-nav {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
  }
}
.Layout__toggle {
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  margin-top: 1rem;
  margin-right: 1rem;
  border-radius: 5px;
  z-index: 99;
  background: #d4b390;
  padding: 8px;
  box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
  .Layout__toggle {
    display: block;
  }
}
