.RadioGroupItem {
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: 1px solid #eae9e9;
  cursor: pointer;
  outline: none;
}
.RadioGroupItem:focus {
  border: 1px solid #ef7f1a;
}
.RadioGroupItem:first-child {
  border-radius: 5px 0 0 5px;
}
.RadioGroupItem:last-child {
  border-radius: 0 5px 5px 0;
}
.RadioGroupItem_active {
  background-color: #d4b390;
  border-color: transparent;
  color: #fff;
  cursor: default;
}
