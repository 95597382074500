.WorkSteps {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .WorkSteps {
    flex-wrap: wrap;
  }
}
.WorkSteps > div {
  width: 25%;
  margin-right: 1rem;
}
@media only screen and (max-width: 768px) {
  .WorkSteps > div {
    width: calc(50% - 0.5rem);
    margin-bottom: 1rem;
  }
}
.WorkSteps > div:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 768px) {
  .WorkSteps :nth-child(2) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .WorkSteps :nth-child(3) {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 768px) {
  .WorkSteps :nth-child(4) {
    margin-right: 0;
    margin-bottom: 0;
  }
}
