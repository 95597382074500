.FooterSection {
  margin-top: 1rem;
}
@media only screen and (max-width: 768px) {
  .FooterSection {
    text-align: center;
    margin-top: 0.5rem;
  }
}
.FooterSection_isCollapsed.FooterSection_isOpen .FooterSection__title:after {
  transform: translateY(-50%) translateX(-8px) rotate(-90deg);
}
.FooterSection_isCollapsed.FooterSection_isOpen .FooterSection__content {
  display: block;
}
@media only screen and (max-width: 768px) {
  .FooterSection_isCollapsed .FooterSection__title {
    color: #fff;
    display: inline-block;
    position: relative;
  }
  .FooterSection_isCollapsed .FooterSection__title:after {
    content: '\203A';
    display: block;
    position: absolute;
    color: #fff;
    right: -22px;
    top: 13px;
    width: 20px;
    height: 20px;
    text-align: center;
    transform: translateY(-50%) rotate(90deg);
  }
}
@media only screen and (max-width: 768px) {
  .FooterSection_isCollapsed .FooterSection__content {
    display: none;
  }
}
.FooterSection__title {
  color: #fff !important;
  font-weight: normal;
}
