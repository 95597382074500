.Snackbar {
  position: relative;
  margin-top: 1rem;
  transition: opacity 250ms linear;
  border-radius: 5px;
  padding: 1.5rem 1rem;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
}
@media only screen and (max-width: 400px) {
  .Snackbar {
    font-size: 14px;
  }
}
.Snackbar_enter-done {
  opacity: 1;
}
.Snackbar_exit {
  opacity: 0;
}
.Snackbar_success {
  background: #fff;
  color: #3e3a39;
}
.Snackbar_success .Snackbar__close:after {
  color: #4e4a49;
}
.Snackbar_info {
  background: #fff;
  color: #3e3a39;
}
.Snackbar_info .Snackbar__close:after {
  color: #3e3a39;
}
.Snackbar_error {
  background: #ca2222;
  color: #fff;
}
.Snackbar_error .Snackbar__close:after {
  color: #fff;
}
.Snackbar__text {
  margin-right: 1rem;
}
.Snackbar__close {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  transition: opacity 250ms;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0Ny45NzEgNDcuOTcxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0Ny45NzEgNDcuOTcxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4LjIyOCwyMy45ODZMNDcuMDkyLDUuMTIyYzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMEwyMy45ODYsMTkuNzQ0TDUuMTIxLDAuODgKCQljLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMGMtMS4xNzIsMS4xNzEtMS4xNzIsMy4wNzEsMCw0LjI0MmwxOC44NjUsMTguODY0TDAuODc5LDQyLjg1Yy0xLjE3MiwxLjE3MS0xLjE3MiwzLjA3MSwwLDQuMjQyCgkJQzEuNDY1LDQ3LjY3NywyLjIzMyw0Ny45NywzLDQ3Ljk3czEuNTM1LTAuMjkzLDIuMTIxLTAuODc5bDE4Ljg2NS0xOC44NjRMNDIuODUsNDcuMDkxYzAuNTg2LDAuNTg2LDEuMzU0LDAuODc5LDIuMTIxLDAuODc5CgkJczEuNTM1LTAuMjkzLDIuMTIxLTAuODc5YzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJMMjguMjI4LDIzLjk4NnoiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  transform: translate(-50%, 50%);
}
.Snackbar__close:hover {
  opacity: 1;
}
