.ClientCard {
  width: 100%;
  max-height: 180px;
  height: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .ClientCard {
    height: 180px;
  }
}
.ClientCard__image-wrapper {
  height: 100px;
  display: flex;
  justify-content: center;
}
.ClientCard__image {
  display: block;
  min-width: 100px;
  height: 100%;
}
.ClientCard__title {
  text-align: center;
  font-weight: bold;
  white-space: normal;
}
