.Post__day {
  color: #adadad;
  margin-bottom: 0.5rem;
}
.Post__photo-wrapper {
  height: 200px;
  margin-bottom: 0.5rem;
}
.Post__photo {
  height: 100%;
  width: 100%;
}
.Post__type {
  color: #adadad;
  font-size: 13px;
  margin-bottom: 0.5rem;
}
.Post__heading {
  display: inline-block;
  margin-bottom: 1rem !important;
}
.Post__heading:hover {
  text-decoration: underline;
}
