.TopNavLink {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  white-space: nowrap;
  display: block !important;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: #d2d2d2;
  transition: 250ms color;
}
.TopNavLink:active {
  color: inherit;
}
.TopNavLink:hover,
.TopNavLink.active {
  color: #fff;
  text-decoration: none;
}
