.Textarea {
  width: 100%;
  resize: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 150ms ease-in;
  color: #3e3a39;
}
.Textarea:focus {
  border-color: #ef7f1a;
}
.Textarea:hover {
  border-color: #ef7f1a;
}
.Textarea_theme-type1 {
  box-sizing: border-box;
  background: #fff;
}
.Textarea_theme-type1::-moz-placeholder {
  color: #4e4a49;
}
.Textarea_theme-type1::placeholder {
  color: #4e4a49;
}
.Textarea_theme-type2 {
  box-sizing: border-box;
  border-color: #d2d2d2;
  background: #fff;
}
.Textarea_theme-type2::-moz-placeholder {
  color: transparent;
}
.Textarea_theme-type2::placeholder {
  color: transparent;
}
.Textarea_error {
  border-color: #ca2222;
}
