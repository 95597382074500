.Slider {
  position: relative;
  width: 100%;
  min-height: 1px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #eae9e9;
  box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
  .Slider {
    border-radius: 0;
  }
}
.Slider_fullscreen:after {
  content: '';
  position: absolute;
  opacity: 0;
  top: -20px;
  right: -20px;
  width: 15px;
  height: 15px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMjQyLjEzMyAyNDIuMTMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNDIuMTMzIDI0Mi4xMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGcgaWQ9IlhNTElEXzg1XyI+Cgk8cGF0aCBpZD0iWE1MSURfODZfIiBkPSJNODkuMjQ3LDEzMS42NzNsLTQ3LjczMiw0Ny43M2wtMTUuOTA5LTE1LjkxYy00LjI5LTQuMjkxLTEwLjc0Mi01LjU3Mi0xNi4zNDctMy4yNTIKCQlDMy42NTQsMTYyLjU2MywwLDE2OC4wMzMsMCwxNzQuMXY1My4wMzJjMCw4LjI4NCw2LjcxNiwxNSwxNSwxNWw1My4wMzMsMC4wMDFjMC4wMDctMC4wMDEsMC4wMTItMC4wMDEsMC4wMTksMAoJCWM4LjI4NSwwLDE1LTYuNzE2LDE1LTE1YzAtNC4zNzctMS44NzUtOC4zMTYtNC44NjUtMTEuMDU5bC0xNS40NTgtMTUuNDU4bDQ3LjczLTQ3LjcyOWM1Ljg1OC01Ljg1OCw1Ljg1OC0xNS4zNTUsMC0yMS4yMTMKCQlDMTA0LjYwMywxMjUuODE1LDk1LjEwNCwxMjUuODE2LDg5LjI0NywxMzEuNjczeiIvPgoJPHBhdGggaWQ9IlhNTElEXzg4XyIgZD0iTTIyNy4xMzMsMEgxNzQuMWMtNi4wNjcsMC0xMS41MzYsMy42NTUtMTMuODU4LDkuMjZjLTIuMzIxLDUuNjA1LTEuMDM4LDEyLjA1NywzLjI1MiwxNi4zNDdsMTUuOTExLDE1LjkxMQoJCWwtNDcuNzI5LDQ3LjczYy01Ljg1OCw1Ljg1OC01Ljg1OCwxNS4zNTUsMCwyMS4yMTNjMi45MjksMi45MjksNi43NjgsNC4zOTMsMTAuNjA2LDQuMzkzYzMuODM5LDAsNy42NzgtMS40NjQsMTAuNjA2LTQuMzk0CgkJbDQ3LjczLTQ3LjczbDE1LjkwOSwxNS45MWMyLjg2OSwyLjg3LDYuNzA2LDQuMzk0LDEwLjYwOSw0LjM5NGMxLjkzMywwLDMuODgyLTAuMzczLDUuNzM3LTEuMTQyCgkJYzUuNjA1LTIuMzIyLDkuMjYtNy43OTIsOS4yNi0xMy44NThWMTVDMjQyLjEzMyw2LjcxNiwyMzUuNDE3LDAsMjI3LjEzMywweiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
  background-position: center;
  background-repeat: no-repeat;
  transition: all 300ms;
}
.Slider:hover .Slider__next {
  opacity: 1;
  right: 20px;
}
.Slider:hover .Slider__prev {
  opacity: 1;
  left: 20px;
}
.Slider:hover.Slider_fullscreen.Slider:after {
  opacity: 1;
  top: 15px;
  right: 15px;
}
@media only screen and (max-width: 768px) {
  .Slider:hover.Slider_fullscreen.Slider:after {
    display: none;
  }
}
.Slider .swiper-pagination {
  color: #fff;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  display: flex;
  position: absolute;
  top: 100%;
  left: 100%;
  width: 40px;
  height: 20px;
  transform: translate(calc(-100% - 15px), calc(-100% - 15px));
  background: rgba(62,58,57,0.7);
  border-radius: 4px;
}
.Slider__previews {
  display: flex;
  height: 80px;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.Slider__previews:before,
.Slider__previews:after {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 99;
  pointer-events: all;
}
.Slider__previews:before {
  box-shadow: 0 0 20px 20px #fff;
  left: 0;
}
.Slider__previews:after {
  box-shadow: 0 0 20px 20px #fff;
  right: 0;
}
.Slider__previews-slider {
  width: 60px !important;
  height: 60px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.Slider__thumb {
  cursor: pointer;
}
.Slider__thumb:hover {
  border-color: #ae937c;
}
.Slider__thumb.swiper-slide-active {
  position: relative;
  top: -3px;
}
.Slider__next,
.Slider__prev {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 999;
  outline: none;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  transition: 300ms all;
  transform: scale(1.01) translateY(-50%);
  box-shadow: 0 0 0 1px transparent, 0 0 0 4px transparent, 0 1px 2px rgba(0,0,0,0.18);
}
@media only screen and (max-width: 768px) {
  .Slider__next,
  .Slider__prev {
    display: none;
  }
}
.Slider__next:after,
.Slider__prev:after {
  content: '';
  display: block;
  position: relative;
  width: 17px;
  height: 100%;
  opacity: 0.8;
  background-position: center;
  background-repeat: no-repeat;
}
.Slider__next:hover,
.Slider__prev:hover {
  opacity: 1;
  transform: scale(1.1) translateY(-50%);
}
.Slider__next:active,
.Slider__prev:active {
  transform: scale(1.01) translateY(-50%);
}
.Slider__next.swiper-button-disabled:hover,
.Slider__prev.swiper-button-disabled:hover {
  opacity: 0.7;
  cursor: auto;
  transform: scale(1.01) translateY(-50%) !important;
}
.Slider__next {
  right: -30px;
}
.Slider__next:after {
  left: 50%;
  transform: translateX(-50%);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCiAgICAgeT0iMHB4IgogICAgIHZpZXdCb3g9IjAgMCA0OTIuMDA0IDQ5Mi4wMDQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ5Mi4wMDQgNDkyLjAwNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0zODIuNjc4LDIyNi44MDRMMTYzLjczLDcuODZDMTU4LjY2NiwyLjc5MiwxNTEuOTA2LDAsMTQ0LjY5OCwwcy0xMy45NjgsMi43OTItMTkuMDMyLDcuODZsLTE2LjEyNCwxNi4xMgoJCQljLTEwLjQ5MiwxMC41MDQtMTAuNDkyLDI3LjU3NiwwLDM4LjA2NEwyOTMuMzk4LDI0NS45bC0xODQuMDYsMTg0LjA2Yy01LjA2NCw1LjA2OC03Ljg2LDExLjgyNC03Ljg2LDE5LjAyOAoJCQljMCw3LjIxMiwyLjc5NiwxMy45NjgsNy44NiwxOS4wNGwxNi4xMjQsMTYuMTE2YzUuMDY4LDUuMDY4LDExLjgyNCw3Ljg2LDE5LjAzMiw3Ljg2czEzLjk2OC0yLjc5MiwxOS4wMzItNy44NkwzODIuNjc4LDI2NQoJCQljNS4wNzYtNS4wODQsNy44NjQtMTEuODcyLDcuODQ4LTE5LjA4OEMzOTAuNTQyLDIzOC42NjgsMzg3Ljc1NCwyMzEuODg0LDM4Mi42NzgsMjI2LjgwNHoiLz4KCTwvZz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgo8L3N2Zz4K);
}
.Slider__prev {
  left: -30px;
}
.Slider__prev:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCiAgICAgeT0iMHB4IgogICAgIHZpZXdCb3g9IjAgMCA0OTIuMDA0IDQ5Mi4wMDQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ5Mi4wMDQgNDkyLjAwNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0zODIuNjc4LDIyNi44MDRMMTYzLjczLDcuODZDMTU4LjY2NiwyLjc5MiwxNTEuOTA2LDAsMTQ0LjY5OCwwcy0xMy45NjgsMi43OTItMTkuMDMyLDcuODZsLTE2LjEyNCwxNi4xMgoJCQljLTEwLjQ5MiwxMC41MDQtMTAuNDkyLDI3LjU3NiwwLDM4LjA2NEwyOTMuMzk4LDI0NS45bC0xODQuMDYsMTg0LjA2Yy01LjA2NCw1LjA2OC03Ljg2LDExLjgyNC03Ljg2LDE5LjAyOAoJCQljMCw3LjIxMiwyLjc5NiwxMy45NjgsNy44NiwxOS4wNGwxNi4xMjQsMTYuMTE2YzUuMDY4LDUuMDY4LDExLjgyNCw3Ljg2LDE5LjAzMiw3Ljg2czEzLjk2OC0yLjc5MiwxOS4wMzItNy44NkwzODIuNjc4LDI2NQoJCQljNS4wNzYtNS4wODQsNy44NjQtMTEuODcyLDcuODQ4LTE5LjA4OEMzOTAuNTQyLDIzOC42NjgsMzg3Ljc1NCwyMzEuODg0LDM4Mi42NzgsMjI2LjgwNHoiLz4KCTwvZz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgogICAgPGc+CjwvZz4KICAgIDxnPgo8L2c+CiAgICA8Zz4KPC9nPgo8L3N2Zz4K);
  transform: rotate(180deg) translateX(-51%);
  left: 1px;
}
