.TopNavLogo {
  font-size: 24px;
  font-weight: bold;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  width: 110px;
  height: 32px;
  text-decoration: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNTMuODEzbW0iIGhlaWdodD0iNDQuNjYxNG1tIiB2ZXJzaW9uPSIxLjEiCiAgICAgc3R5bGU9InNoYXBlLXJlbmRlcmluZzpnZW9tZXRyaWNQcmVjaXNpb247IHRleHQtcmVuZGVyaW5nOmdlb21ldHJpY1ByZWNpc2lvbjsgaW1hZ2UtcmVuZGVyaW5nOm9wdGltaXplUXVhbGl0eTsgZmlsbC1ydWxlOmV2ZW5vZGQ7IGNsaXAtcnVsZTpldmVub2RkIgogICAgIHZpZXdCb3g9IjAgMCAxNTQ4MCA0NDk1Igo+CiA8ZGVmcz4KICA8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgogICA8IVtDREFUQVsKICAgIC5maWwwIHtmaWxsOiNFRjdGMUE7ZmlsbC1ydWxlOm5vbnplcm99CiAgIF1dPgogIDwvc3R5bGU+CiA8L2RlZnM+CiAgICA8ZyBpZD0i0KHQu9C+0LlfeDAwMjBfMSI+CiAgICAgICAgPHBhdGggY2xhc3M9ImZpbDAiCiAgICAgICAgICAgICAgZD0iTTIwMzEgNDhsMTg2MyAxMDc2IDAgNTg2IC0xNjkgMjE1IC0xNjkgLTIyNiAwIC0zODIgLTgwNCAtNDY0IC04MDUgLTQ2NSAtMTQyOCA4MjUgMTU5NyA4MjggMCAxOTY4IDYzNiAtMzY3IDAgMCA4MDQgLTQ2NSAwIC0yIDMzOCAwIDAgMTk1IC05NzQgNTYzIDAgMCAtOTczIDU2MiAtOTc0IC01NjIgMSAwIC05NzQgLTU2MyAwIC0yMjQ2IDE5NDcgLTExMjQgODQgNDh6bS0xNjk0IDE0NTBsMCAxNjc5IDgwNSA0NjUgMCAwIDYzNiAzNjcgMCAtMTc2NCAtMTQ0MSAtNzQ3eiIvPgogICAgICAgIDxwYXRoIGNsYXNzPSJmaWwwIgogICAgICAgICAgICAgIGQ9Ik00OTUwIDMwNDNsLTQ4MCAwIDAgLTEwMTcgLTU5NSA4MjAgLTMxMCAwIC01OTMgLTgyMCAwIDEwMTcgLTQ4MCAyNzQgMCAtMTg2MiA1NDIgMCA2ODcgOTQ2IDY4NSAtOTQ2IDU0NCAwIDAgMTU4OHptMTk4MSAtOTAxbC0xOTkgMzU2IC0xMzA1IDAgMjAwIC0zNTYgMTMwNCAwem0yMTk0IDkwNmwtNDgwIDAgMCAtOTQ2IC0xMTQ4IDk0NiAtMzQyIDAgMCAtMTg2MyA0NzkgMjc1IDAgOTI4IDExMjcgLTkyOCAzNjQgMCAwIDE1ODh6bTIzMzEgLTNsLTQ3OSAwIDAgLTYzMyAtOTQ5IDAgMCA2MzMgLTQ4MCAtMiAwIC0xNTg4IDQ4MCAwIDAgNjMzIDk0OSAwIDAgLTYzMyA0NzkgLTI3OCAwIDE4Njh6bTE4NDYgLTkwM2wtMTk5IDM1NiAtMTMwNSAwIDIwMCAtMzU2IDEzMDQgMHptMjE3OCAtNjg3bC0zNTMgMzU2IC0xMDY3IDAgMCA4NzggMTM2NCAwIC0zNTYgMzU2IC0xMTI3IDAgLTM2MSAtMzYxIDAgLTg2OCAzNjEgLTM2MSAxNTM5IDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-size: 110px;
}
.TopNavLogo:hover {
  text-decoration: none;
}
