.withInputFile__input {
  display: none;
}
.withInputFile__button {
  width: 100%;
  outline: none;
  background: #fff !important;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 150ms ease-in;
  display: flex;
  position: relative;
  padding-right: 40px !important;
  cursor: pointer;
  outline: none;
}
.withInputFile__button:focus {
  border: 1px solid #ef7f1a;
}
.withInputFile__button:hover {
  border-color: #ef7f1a;
}
.withInputFile__button:before {
  position: absolute;
  content: '';
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAxMyA0NSAzMiI+CiAgPGc+CiAgICA8cGF0aCBkPSJNMzcuODUsMjMuNzFhOS43Niw5Ljc2LDAsMCwwLTkuMS03LDkuNjUsOS42NSwwLDAsMC0yLjIzLjIxQTEwLDEwLDAsMCwwLDI0LDE0LjQ1VjI0Ljg4bDQuNDQtNC40NGExLjUsMS41LDAsMCwxLDIuMTIsMi4xMmwtNyw3YTEuNTEsMS41MSwwLDAsMS0uNDkuMzNoMGExLjQzLDEuNDMsMCwwLDEtMS4xLDBoMGExLjUxLDEuNTEsMCwwLDEtLjQ5LS4zM2wtNy03YTEuNSwxLjUsMCwwLDEsMi4xMi0yLjEyTDIxLDI0Ljg4VjEzLjA3QTkuNzMsOS43MywwLDAsMCw4Ljc0LDIyLjI1YTgsOCwwLDAsMCwwLDFBMTEsMTEsMCwwLDAsMTEsNDVIMzRhMTEsMTEsMCwwLDAsMy44NS0yMS4yOVoiIGZpbGw9IiNkNGIzOTAiLz4KICA8L2c+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  width: 25px;
  height: 17px;
  right: 8px;
  top: 50%;
  transform: translate(0%, -50%);
  display: block;
}
.withInputFile__button_not-empty:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAxMyA0NSAzMiI+CiAgPGc+CiAgICA8cGF0aCBkPSJNMzcuODUsMjMuNzFhOS43Niw5Ljc2LDAsMCwwLTkuMS03LDkuNjUsOS42NSwwLDAsMC0yLjIzLjIxQTEwLDEwLDAsMCwwLDI0LDE0LjQ1VjI0Ljg4bDQuNDQtNC40NGExLjUsMS41LDAsMCwxLDIuMTIsMi4xMmwtNyw3YTEuNTEsMS41MSwwLDAsMS0uNDkuMzNoMGExLjQzLDEuNDMsMCwwLDEtMS4xLDBoMGExLjUxLDEuNTEsMCwwLDEtLjQ5LS4zM2wtNy03YTEuNSwxLjUsMCwwLDEsMi4xMi0yLjEyTDIxLDI0Ljg4VjEzLjA3QTkuNzMsOS43MywwLDAsMCw4Ljc0LDIyLjI1YTgsOCwwLDAsMCwwLDFBMTEsMTEsMCwwLDAsMTEsNDVIMzRhMTEsMTEsMCwwLDAsMy44NS0yMS4yOVoiIGZpbGw9IiNlZjdmMWEiLz4KICA8L2c+Cjwvc3ZnPgo=);
}
.withInputFile__button_theme-type1 {
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: transparent;
}
.withInputFile__button_theme-type1::-moz-placeholder {
  color: #4e4a49;
}
.withInputFile__button_theme-type1::placeholder {
  color: #4e4a49;
}
.withInputFile__button_theme-type2 {
  padding: 0.5rem;
  box-sizing: border-box;
  border-color: #d2d2d2;
  background-color: transparent;
}
.withInputFile__button_error {
  border-color: #ca2222;
}
